import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import CustomLink from "../components/CustomLink";

const CaseStudyKings = ({ includeCTALink, externalLink }) => {
  return (
    <section>
      {/*  King's College London use case  */}
      <div className="relative max-w-6xl px-4 mx-auto sm:px-6">
        <div>
          <div className="relative inline-flex flex-col">
            <StaticImage
              loading="lazy"
              src={"../assets/images/kcl-logo.png"}
              width={140}
              alt="KCL Logo"
            />
          </div>
        </div>
        <div className="pt-4">
          <div className="h4">King's College London</div>
          <p className="text-xl text-gray-600">
            KCL used Encord to achieve a 6.4x average increase in labeling
            efficiency for GI videos.
          </p>
        </div>
      </div>

      {/* Results */}
      <div className="max-w-6xl px-4 mx-auto sm:px-6">
        <div className="py-4 md:pb-4 md:pt-4">
          {/* Items */}
          <div className="grid items-start max-w-sm gap-8 pt-8 mx-auto md:gap-16 md:grid-cols-2 lg:grid-cols-3 md:max-w-2xl lg:max-w-5xl md:pt-4">
            {/* 1st item */}
            <div className="text-left">
              <div className="mb-1 h4">Problem</div>
              <div className="text-gray-600">
                Using clinicians to annotate pre-cancerous polyp videos had
                prohibitively high costs to produce large datasets.
              </div>
            </div>

            {/* 2nd item */}
            <div className="text-left">
              <div className="mb-1 h4">Solution</div>
              <div className="text-gray-600">
                Deployed Encord's micro-model module to increase clinician
                labeling efficiency and automate 97% of produced labels.
              </div>
            </div>

            {/* 3rd item */}
            <div className="text-left">
              <div className="mb-1 h4">Results</div>
              <div className="text-gray-600">
                Highest expense clinician saw 16x labeling efficiency
                improvement. Cut model development time from {""}
                <a
                  className="underline"
                  target="_blank"
                  rel="noopener noreferrer" href={externalLink}>1 year to 2 months.</a>
              </div>
            </div>

            {/* 1st item */}
            <div className="text-center bg-white border-gray-200 shadow-lg">
              <div className="mb-1 text-transparent h4 bg-clip-text bg-gradient-to-r from-purple-500 to-green-400">
                6.4X
              </div>
              <div className="py-4 text-gray-600">
                Faster than manual labeling
              </div>
            </div>

            {/* 2nd item */}
            <div className="text-center bg-white border-gray-200 shadow-lg">
              <div className="mb-1 text-transparent h4 bg-clip-text bg-gradient-to-r from-purple-500 to-green-400">
                97%
              </div>
              <div className="py-4 text-gray-600">Automated labels</div>
            </div>

            {/* 3rd item */}
            <div className="text-center bg-white border-gray-200 shadow-lg">
              <div className="mb-1 text-transparent h4 bg-clip-text bg-gradient-to-r from-purple-500 to-green-400">
                6X
              </div>
              <div className="py-4 text-gray-600">
                Faster to AI in production
              </div>
            </div>
          </div>
        </div>

        {includeCTALink ? (
          <div className="flex items-end justify-end">
            <CustomLink
              to="/try-it-free/"
              className="flex items-center px-5 py-3 font-medium text-gray-600 transition duration-150 ease-in-out hover:text-gray-900"
            >
              <span>
                Get Free Trial To Learn How KCL Got to Production AI Faster
              </span>
              <svg
                className="flex-shrink-0 w-3 h-3 ml-2 -mr-1 text-gray-400 fill-current"
                viewBox="0 0 12 12"
                xmlns="https://www.w3.org/2000/svg"
              >
                <path
                  d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z"
                  fillRule="nonzero"
                />
              </svg>
            </CustomLink>
          </div>
        ) : (
          <div className="flex items-end justify-end">
            <a
              href="https://encord.com/customers/kings-college-london-customer-story/"
              className="flex items-center px-5 py-3 font-medium text-gray-600 transition duration-150 ease-in-out hover:text-gray-900"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>Learn more</span>
              <svg
                className="flex-shrink-0 w-3 h-3 ml-2 -mr-1 text-gray-400 fill-current"
                viewBox="0 0 12 12"
                xmlns="https://www.w3.org/2000/svg"
              >
                <path
                  d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z"
                  fillRule="nonzero"
                />
              </svg>
            </a>
          </div>
        )}
      </div>
    </section>
  );
};

export default CaseStudyKings;
